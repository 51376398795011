import React, { useEffect, useState } from 'react';
import styles from './Section2.module.scss';
import classNames from 'classnames/bind';
import PrimaryButton from '~/components/Layouts/components/PrimaryButton';
import RoundedButton from '~/components/Layouts/components/RoundedButton';
import { sofin2, like, facebook, telegram, tiktok, twitter, twitch, youtube } from '~/assets/images';
import PartnerSwiper from '~/components/Layouts/components/PartnerSwiper';
import CreatorList from '~/components/Layouts/components/CreatorList';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
const cx = classNames.bind(styles);

const partners = [
  { name: 'Facebook', icon: facebook },
  { name: 'Telegram', icon: telegram },
  { name: 'TikTok', icon: tiktok },
  { name: 'Twitch', icon: twitch },
  { name: 'Twitter', icon: twitter },
  { name: 'Youtube', icon: youtube },
  { name: 'Facebook', icon: facebook },
  { name: 'Telegram', icon: telegram },
  { name: 'TikTok', icon: tiktok },
  { name: 'Twitch', icon: twitch },
  { name: 'Twitter', icon: twitter },
  { name: 'Youtube', icon: youtube },
];

const creatorLists = [
  {
    name: 'A Content Enthusiast',
    desc: "Have a passion for creativity but don't know where to start and have no basic knowledge",
  },
  {
    name: 'A Content Creator',
    desc: 'Have the potential to create content, have made content but do not know how to optimize, do not have a foundation',
  },
  {
    name: 'Team Leader Creator',
    desc: 'A leader of a content creation team without a professional development background or process',
  },
  {
    name: 'A Content Creator',
    desc: 'Have the potential to create content, have made content but do not know how to optimize, do not have a foundation',
  },
];

function _onJoin() {
  console.log('on join');
}
function Section2() {
  return (
    <>
      <div className={cx('decor--line', 'w-full')}>
        <div className={cx('sec-2', 'flex flex-col max-w-screen-2xl px-5 lg:px-10 m-auto relative')}>
          <span className={cx('text-2xl my-16')}>Why choose Us</span>
          <div className={cx('flex lg:pl-24 content-between')}>
            <div className={cx('flex flex-col w-full lg:w-2/3')}>
              <h2 className={cx('text-2xl lg:text-7xl mb-4 lg:mb-12')}>Trust the timing of your creativity with us.</h2>
              <span className={cx('t--hl')}>
                Attractive <i>passive income</i>
              </span>
              <span className={cx('t--hl')}>
                Attractive <i>direct income from your video</i>
              </span>
              <span className={cx('t--hl')}>
                Core products <i>with extensive server & channel system </i>
              </span>
              <span className={cx('t--hl')}>
                More than <i>50 ecosystem projects support</i> content development for you{' '}
              </span>
              <span className={cx('t--hl mb-24')}>
                Regularly update the <i>social media policies</i> from a team of hot creators
              </span>
              <PrimaryButton onClick={_onJoin}>
                Join now!
              </PrimaryButton>
            </div>
            <div className={cx('hidden lg:flex flex-col w-1/3 items-center')}>
              <img src={like} className={cx('object-contain')} alt="SOFIN NETWORK" />
              <img src={sofin2} className={cx('object-contain')} alt="SOFIN NETWORK" />
            </div>
          </div>
        </div>
      </div>
      <div className={cx('decor--round', 'w-full')}>
        <p className={cx('sec-desc', 'mt-16 py-5 lg:py-24 max-w-screen-2xl px-5 lg:px-10 m-auto')}>
          Be Our&nbsp;
          <span>
            Star, <br></br>if you're
          </span>
          available on.
        </p>
      </div>
      <PartnerSwiper partners={partners} className={cx('mt-14 mb-24')} />
      <CreatorList creators={creatorLists} />
      <div className={cx('sec-2__cta', 'flex flex-col justify-center items-center m-auto')}>
        <span className={cx('text-5xl text-center')}>Be the NEWGEN at SOFIN Network</span>
        <RoundedButton className={cx('mt-14')}>Get in touch</RoundedButton>
      </div>
    </>
  );
}

export default Section2;
