import styles from './StatisticList.module.scss';
import classNames from 'classnames/bind';
import React, { useRef, useState } from 'react';
import { shortenValue } from '~/utils/services';
import { banner3 } from '~/assets/images';

const cx = classNames.bind(styles);
function StatisticList({ className, statistics, props }) {
  return (
    <div
      className={
        (className,
        'flex justify-between my-10 lg:my-36 w-full max-w-screen-2xl px-5 lg:px-10 m-auto relative flex-wrap')
      }
      {...props}
    >
      {statistics.map((stat, i) => (
        <div key={i} className={cx('stat', 'w-1/2 lg:w-1/5 px-4 flex flex-col justify-center items-center')}>
          <span className={cx('text-4xl lg:text-6xl')}>
            <span className={cx('stat__plus')}>+</span>
            {stat.unit ?? ''}
            {shortenValue(stat.value)}
          </span>
          <p className={cx('stat__label', 'text-center mt-2.5')}>{stat.label}</p>
        </div>
      ))}
      <div className={cx('stat', 'w-1/5 lg:flex flex-col items-center justify-center hidden')}>
        <img className={cx('stat__img')} src={banner3} alt="SOFIN" />
      </div>
    </div>
  );
}
export default StatisticList;
