import React, { useEffect, useState } from 'react';
import styles from './Section4.module.scss';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import StatisticList from '~/components/Layouts/components/StatisticList';
import KOLSwiper from '~/components/Layouts/components/KOLSwiper/KOLSwiper';
import RoundedButton from '~/components/Layouts/components/RoundedButton/RoundedButton';
import { kol1, kol2, kol3 } from '~/assets/images';
import CircleButton from '~/components/Layouts/components/CircleButton/CircleButton';
const cx = classNames.bind(styles);
const statistics = [
  { label: 'Certified influencers', value: 4000.11 },
  { label: 'Brand/Influencer Collaborations', value: 1000.11 },
  { label: 'Followers on Social', value: 15000000.11 },
  { label: 'Paid out to creators', value: 2500000.22, unit: '$' },
];

const influencers = [
  { label: 'Free choice of personal images' },
  { label: 'Income transparency' },
  { label: 'Optimizing revenue' },
  { label: 'Sustainable development' },
  { label: 'Academy program' },
];

const KOLs = [
  {
    name: 'Đỗ Thị Minh Anh',
    participateDate: '20/06/2023',
    ranking: 1,
    image: kol1,
  },
  {
    name: 'Đỗ Thị Minh Anh',
    participateDate: '20/06/2023',
    ranking: 2,
    image: kol2,
  },
  {
    name: 'Đỗ Thị Minh Anh',
    participateDate: '20/06/2023',
    ranking: 3,
    image: kol3,
  },
  {
    name: 'Đỗ Thị Minh Anh',
    participateDate: '20/06/2023',
    ranking: 1,
    image: kol1,
  },
  {
    name: 'Đỗ Thị Minh Anh',
    participateDate: '20/06/2023',
    ranking: 2,
    image: kol2,
  },
  {
    name: 'Đỗ Thị Minh Anh',
    participateDate: '20/06/2023',
    ranking: 3,
    image: kol3,
  },
];

const trendVideos = [
  {
    title: 'Các bước makeup dành cho phái đẹp',
    publishedDate: '20/06/2023',
    link: '#',
    thumb: kol1,
  },
  {
    title: 'Các bước makeup dành cho phái đẹp',
    publishedDate: '20/06/2023',
    link: '#',
    thumb: kol2,
  },
  {
    title: 'Các bước makeup dành cho phái đẹp',
    publishedDate: '20/06/2023',
    link: '#',
    thumb: kol3,
  },
  {
    title: 'Các bước makeup dành cho phái đẹp',
    publishedDate: '20/06/2023',
    link: '#',
    thumb: kol1,
  },
  {
    title: 'Các bước makeup dành cho phái đẹp',
    publishedDate: '20/06/2023',
    link: '#',
    thumb: kol2,
  },
  {
    title: 'Các bước makeup dành cho phái đẹp',
    publishedDate: '20/06/2023',
    link: '#',
    thumb: kol3,
  },
  {
    title: 'Các bước makeup dành cho phái đẹp',
    publishedDate: '20/06/2023',
    link: '#',
    thumb: kol1,
  },
  {
    title: 'Các bước makeup dành cho phái đẹp',
    publishedDate: '20/06/2023',
    link: '#',
    thumb: kol2,
  },
  {
    title: 'Các bước makeup dành cho phái đẹp',
    publishedDate: '20/06/2023',
    link: '#',
    thumb: kol3,
  },
];

const filterButtons = [
  {
    type: 1,
    label: 'Beauty',
  },
  {
    type: 2,
    label: 'Music',
  },
  {
    type: 3,
    label: 'Lifestyle',
  },
  {
    type: 4,
    label: 'Study',
  },
  {
    type: 5,
    label: 'Film',
  },
];
function Section4() {
  const [currentFilter, changeFilter] = useState(1);
  const onFilter = (type) => {
    changeFilter(type);
  };
  return (
    <>
      <div className={cx('sec-4', 'w-full flex flex-col py-10 lg:py-24')}>
        <div className={cx('flex flex-col lg:flex-row w-full max-w-screen-2xl px-5 lg:px-10 m-auto relative')}>
          <div className={cx('w-full lg:w-3/5')}>
            <span className={cx('sec-desc')}>Join our private influencer network</span>
          </div>
          <div className={cx('w-full lg:w-2/5 lg:pl-16')}>
            <p className={cx('t--gray', 'text-base lg:text-2xl')}>
              Join a global community of influencers across beauty, fashion, lifestyle and more!
            </p>
            <ul className={cx('mt-10 lg:mt-32')}>
              {influencers.map((influence, i) => (
                <li key={i} className={cx('influence', 'text-base lg:text-2xl')}>
                  &#62;&#160;{influence.label}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <StatisticList statistics={statistics} />
        <div className={cx('flex flex-col w-full max-w-screen-2xl px-5 lg:px-10 m-auto relative')}>
          <div className={cx('w-full lg:w-3/5 flex flex-col')}>
            <span className={cx('sec-desc')}>Meet our outstanding creators</span>
            <p className={cx('t--gray', 'mt-4 text-base lg:text-2xl')}>
              They are all positive inspirations to the community
            </p>
          </div>
          <KOLSwiper className={cx('w-full max-w-screen-2xl m-auto relative')} KOLs={KOLs} />
        </div>

        <div className={cx('flex flex-col w-full max-w-screen-2xl px-5 lg:px-10 m-auto relative')}>
          <span className={cx('text-2xl lg:text-5xl')}>Check out the trending videos</span>
          <div className={cx('flex flex-col-reverse items-end lg:flex-row justify-between lg:items-center mt-10')}>
            <div className={cx('flex w-full overflow-x-auto')}>
              {filterButtons.map((filter, i) => (
                <RoundedButton
                  key={i}
                  onClick={() => onFilter(filter.type)}
                  color={currentFilter === filter.type ? '#000' : '#fff'}
                  background={currentFilter === filter.type ? '#fff' : 'transparent'}
                  border="1px solid #fff"
                  padding="1rem 3rem"
                  active={currentFilter === filter.type}
                  className={cx('mr-4')}
                >
                  {filter.label}
                </RoundedButton>
              ))}
            </div>
            <div className={cx('flex items-center mb-5 lg:mb-0')}>
              <CircleButton color="#fff" background="#404040" className={cx('ctrl-btn')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M11.6476 20.6169L2.40346 11.3727M2.40346 11.3727L11.4494 2.32677M2.40346 11.3727L22.3467 11.582"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinejoin="round"
                  />
                </svg>
              </CircleButton>
              <CircleButton color="#fff" background="#404040" className={cx('ctrl-btn', 'ml-2.5')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M12.3524 20.6169L21.5965 11.3727M21.5965 11.3727L12.5506 2.32677M21.5965 11.3727L1.65332 11.582"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinejoin="round"
                  />
                </svg>
              </CircleButton>
            </div>
          </div>
        </div>
        <div
          className={cx(
            'tr-videos',
            'flex w-full flex-wrap justify-between my-20 max-w-screen-2xl px-5 lg:px-10 m-auto relative',
          )}
        >
          {trendVideos.map((video, i) => (
            <div key={i} className={cx('tr-videos__video')}>
              <img className={cx('tr-videos__video__thumb')} src={video.thumb} alt={video.title} />
              <div className={cx('flex flex-col justify-center ml-4')}>
                <span className={cx('text-lg')}>{video.title}</span>
                <span className={cx('text-sm')}>Ngày phát hành:</span>
                <span className={cx('text-sm')}>{video.publishedDate}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Section4;
