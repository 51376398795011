import React, { useEffect, useState } from 'react';
import styles from './Section6.module.scss';
import classNames from 'classnames/bind';
import { java, react, kotlin, swift } from '~/assets/images';
import CircleButton from '~/components/Layouts/components/CircleButton/CircleButton';
import TechSwiper from '~/components/Layouts/components/TechSwiper/TechSwiper';
const cx = classNames.bind(styles);

const faqs = [
  {
    question: 'What brands can I work with?',
    answer:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
  },
  {
    question: 'How long will the Academy SOFIN program last?',
    answer:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
  },
  {
    question: 'Can a beginner creator group join an Affiliate Program?',
    answer:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
  },
  {
    question: 'How quickly do I get paid?',
    answer:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
  },
];

const logos = [
  java,
  react,
  kotlin,
  swift,
  java,
  react,
  kotlin,
  swift,
  java,
  react,
  kotlin,
  swift,
  java,
  react,
  kotlin,
  swift,
];
function Section6() {
  const [currentAns, changeAns] = useState(-1);
  const onToggleFaq = (type) => {
    if (type === currentAns) changeAns(-1);
    else changeAns(type);
  };
  return (
    <>
      <div className={cx('sec-6', 'w-full decor--line flex flex-col py-24')}>
        <div className={cx('flex w-full max-w-screen-2xl px-5 lg:px-10 m-auto relative')}>
          <div className={cx('w-3/5')}>
            <span className={cx('sec-desc')}>Frequently asked questions</span>
          </div>
        </div>
        <div className={cx('flex flex-col w-full mt-10 lg:mt-24 max-w-screen-2xl px-5 lg:px-10 m-auto relative')}>
          {faqs.map((faq, i) => (
            <div key={i} className={cx('faq', 'flex flex-col w-full lg:w-3/4 m-auto mb-5 lg:mb-8')}>
              <div className={cx('flex justify-between items-center')}>
                <span className={cx('text-base lg:text-3xl')}>{faq.question}</span>
                <CircleButton onClick={() => onToggleFaq(i)} className={cx({ 'rotate-90': currentAns === i })}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M12.3524 20.6169L21.5965 11.3727M21.5965 11.3727L12.5506 2.32677M21.5965 11.3727L1.65332 11.582"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinejoin="round"
                    />
                  </svg>
                </CircleButton>
              </div>
              <p className={cx('mt-8 text-base', { hidden: currentAns !== i })}>{faq.answer}</p>
            </div>
          ))}
        </div>
      </div>
      <TechSwiper logos={logos}></TechSwiper>
    </>
  );
}

export default Section6;
