import styles from './TechSwiper.module.scss';
import classNames from 'classnames/bind';
import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
const cx = classNames.bind(styles);

function TechSwiper({ className, logos, slideClassName, props }) {
  return (
    <Swiper
      slidesPerView={3}
      spaceBetween={0}
      autoplay={{
        delay: 2000,
        disableOnInteraction: false,
      }}
      breakpoints={{
        640: {
          slidesPerView: 3,
        },
        768: {
          slidesPerView: 5,
        },
        1024: {
          slidesPerView: 9,
        },
      }}
      modules={[Autoplay]}
      className={cx(className)}
      {...props}
    >
      {logos.map((logo, i) => (
        <SwiperSlide key={i} className={cx(slideClassName, 'flex items-center justify-center px-2.5 py-16 lg:px-9')}>
          <img className={cx('mr-4')} src={logo} alt="Tech" />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
export default TechSwiper;
