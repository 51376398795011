import styles from './LanguageSwitch.module.scss';
import classNames from 'classnames/bind';
import { bottomArrow } from '~/assets/images/';
import React, { useState, useEffect } from 'react';
const cx = classNames.bind(styles);

const languages = [
  { key: 1, label: 'ENG' },
  { key: 2, label: 'VIE' },
];
function LanguageSwitch({ className }) {
  const [isShow, setIsShow] = useState(false);
  const [currentLang, setLang] = useState(languages[0].label);
  const onSwitch = (key, e) => {
    const languageTarget = languages.find((language) => language.key === key);
    setLang(languageTarget.label);
    setIsShow(false);
    e.stopPropagation();
  };
  const onToggle = () => {
    setIsShow(true);
  };

  return (
    <div className={cx('language-switch', 'flex items-center justify-center', className)} onClick={onToggle}>
      <span className={cx('mr-2')}>{currentLang}</span>
      <img src={bottomArrow} alt="Sofin Network" />
      <ul className={cx('language-switch__list', { 'language-switch__list--show': isShow })}>
        {languages.map((lang, i) => (
          <li key={i} className={cx('flex items-center justify-center')} onClick={(e) => onSwitch(lang.key, e)}>
            <span className={cx('mr-2')}>{lang.label}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}
export default LanguageSwitch;
