import React, { useEffect, useRef } from 'react';
import styles from './Home.module.scss';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Section4 from './Section4';
import Section5 from './Section5';
import Section6 from './Section6';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);
function Home() {
  const sectionRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const section = sectionRef.current;
      const sectionRect = section.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      let scrollPercentage = 100;
      let color = '#fff';
      // Check if the section is in the viewport
      if (sectionRect.top <= windowHeight && sectionRect.bottom >= 0) {
        if (sectionRect.top > 0) {
          scrollPercentage = Math.round((((windowHeight - Math.abs(sectionRect.top)) * 1.5) / windowHeight) * 100);
        }

        if (sectionRect.bottom - windowHeight < 0) {
          scrollPercentage =
            100 - Math.round((((windowHeight - Math.abs(sectionRect.bottom)) * 1.5) / windowHeight) * 100);
          color = '#000';
        }
        // Calculate the gradient colors based on the scroll position
        const endColor = [0, 0, 0]; // White
        const startColor = [255, 255, 255]; // Black
        const gradientColors = startColor.map((channel, index) => {
          const channelDiff = endColor[index] - channel;
          return Math.round(channel + (channelDiff * scrollPercentage) / 100);
        });

        // Update the background gradient with the calculated colors
        const gradient = `linear-gradient(to bottom, rgb(${gradientColors.join(',')}), rgb(${gradientColors.join(
          ',',
        )}))`;
        const bodyElement = document.getElementById('body');
        bodyElement.style.background = gradient;
        bodyElement.style.color = color;
      } else {
        const bodyElement = document.getElementById('body');
        bodyElement.style.background = '#fff';
        bodyElement.style.color = '#000';
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div id="body">
      <Section2 />
      <Section3 />
      <div ref={sectionRef} className={cx('Section', 'mt-10 lg:mt-40')}>
        <Section4 />
      </div>
      <Section5 />
      <Section6 />
    </div>
  );
}

export default Home;
