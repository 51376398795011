import styles from './KOLSwiper.module.scss';
import classNames from 'classnames/bind';
import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
const cx = classNames.bind(styles);

function KOLSwiper({ className, KOLs, slideClassName, props }) {
  return (
    <Swiper
      slidesPerView={'auto'}
      spaceBetween={30}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      breakpoints={{
        640: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 3,
        },
      }}
      modules={[Autoplay]}
      className={cx('kol-swiper', className)}
      {...props}
    >
      {KOLs.map((KOL, i) => (
        <SwiperSlide
          key={i}
          className={cx(slideClassName, 'kol-swiper__slide', 'w-10/12 lg:w-full flex flex-col my-10 lg:my-16')}
        >
          <img className={cx('kol-swiper__slide__image', 'w-full')} src={KOL.image} alt={KOL.name} />
          <span className={cx('kol-swiper__slide__name', 'w-full text-base lg:text-2xl my-4')}>{KOL.name}</span>
          <div className={cx('flex')}>
            <div className={cx('w-3/5')}>
              <span className={cx('text-xs lg:text-sm')}>Ngày tham gia Network:</span>
              <p className={cx('text-xs lg:text-sm')}>{KOL.participateDate}</p>
            </div>
            <div className={cx('w-2/5')}>
              <span className={cx('text-xs lg:text-sm')}>Ranking:</span>
              <p className={cx('text-xs lg:text-sm')}>Top {KOL.ranking}</p>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
export default KOLSwiper;
