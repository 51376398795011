import React, { useEffect, useState } from 'react';
import styles from './Section3.module.scss';
import classNames from 'classnames/bind';
import { animate } from '~/assets/images';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import PrimaryButton from '~/components/Layouts/components/PrimaryButton';
const cx = classNames.bind(styles);

const tabs = [
  {
    label: 'Attractive revenue',
    href: '#',
  },
  {
    label: 'Unlimited number of creators',
    href: '#',
  },
  {
    label: 'Management support',
    href: '#',
  },
];

const links = [
  { label: 'Platform', href: '#' },
  { label: 'Technology', href: '#' },
  { label: 'Academy', href: '#' },
  { label: 'Knowledge', href: '#' },
  { label: 'Revenue', href: '#' },
  { label: 'Income', href: '#' },
];
function Section3() {
  const [currentTab, changeTab] = useState(0);
  const onChangeTab = (tab) => {
    changeTab(tab);
  };
  return (
    <>
      <div className={cx('sec-3', 'w-full flex flex-col justify-center')}>
        <div
          className={cx(
            'sec-3__hero',
            'py-16 lg:py-36 w-full max-w-screen-2xl px-5 lg:px-10 m-auto relative overflow-hidden',
          )}
        >
          <img src={animate} className={cx('sec-3__hero__animate')} alt="SOFIN NETWORK" />
          <div className={cx('sec-3__hero__content', 'flex flex-col')}>
            <p className={cx('text-base lg:text-2xl text-right mb-10 lg:mb-0')}>
              Direct Commission from Creator Income
            </p>
            <span className={cx('t--head', 'my-0 lg:my-20')}>Affiliate</span>
            <span className={cx('t--head', 'my-0 lg:my-20', 'text-right')}>Program</span>
            <div className={cx('flex flex-col lg:flex-row items-center mt-10 lg:mt-0')}>
              <p className={cx('text-base lg:text-2xl w-full lg:w-1/2')}>
                If you are a leader/trainer who owns a group of creators and want to collaborate to grow, join SOFIN
                Network with lots of benefits and terms that suit your creators.
              </p>
              <div className={cx('w-full lg:w-1/2 flex h-fit justify-center lg:justify-end mt-10')}>
                <PrimaryButton isBlack="true" onClick={() => {}} style={{ style: 300 }}>
                  Contact for details
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
        <Swiper
          slidesPerView={'auto'}
          spaceBetween={0}
          breakpoints={{
            640: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
          className={cx('w-full max-w-screen-2xl px-10 ')}
        >
          <div className={cx('sec-3__tabs', 'flex max-w-screen-2xl px-10 m-auto')}>
            {tabs.map((tab, i) => (
              <SwiperSlide
                key={i}
                className={cx('sec-3__tabs__tab', 'lg:flex-1 p-5 h-auto lg:p-10 lg:w-full', {
                  'sec-3__tabs__tab--active': currentTab === i,
                })}
                onClick={() => onChangeTab(i)}
              >
                <span className={cx('text-white text-base lg:text-3xl')}>{tab.label}</span>
              </SwiperSlide>
            ))}
          </div>
        </Swiper>
      </div>
      {/* <div className={cx('api-dashboard', 'my-24 w-full flex max-w-screen-2xl px-5 lg:px-10 lg:py-24 m-auto')}>
        <div className={cx('w-full bg-slate-50 flex items-center justify-center rounded-lg')}>DASHBOARD</div>
      </div> */}
      <div className={cx('decor--1-round decor--spiral', 'w-full py-10 mt-48')}>
        <div className={cx('w-full flex justify-between max-w-screen-2xl px-5 lg:px-10 m-auto flex-col lg:flex-row')}>
          <div className={cx('w-2/3 flex flex-col')}>
            <span className={cx('sec-desc')}>Committed to promote the culture of creativity</span>
            <p>We offer</p>
          </div>
          <ul className={cx('w-1/3 pl-0 lg:pl-10 my-5 lg:my-0')}>
            {links.map((link, i) => (
              <li key={i} className={cx('mb-2.5 lg:mb-5')}>
                <Link className={cx('link')} to={link.href}>
                  {link.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className={cx('w-full flex flex-col max-w-screen-2xl px-5 lg:px-10 m-auto relative z-10')}>
          <span className={cx('w-full lg:w-2/3 sec-desc')}>SOFIN Academy Program</span>
          <p className={cx('mt-10')}>
            In addition to lecturers who are successful influencers on platforms like YouTube, Facebook, Tiktok... We
            constantly update policies and terms on social networks. We analyze content trends and user tastes through
            advanced technology and data analysis systems and a standard training process to become a professional
            creator.
          </p>
        </div>
      </div>
    </>
  );
}

export default Section3;
