import Header from '~/components/Layouts/components/Header';
import Footer from '~/components/Layouts/components/Footer';
import styles from './DefaultLayout.module.scss';
import classNames from 'classnames/bind';
import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Section1 from '~/pages/Home/Section1/Section1';

const cx = classNames.bind(styles);
function DefaultLayout({ children }) {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <>
      <Header />
      <Section1 />
      <div className={cx('body z-10 relative')}>
        <div className={cx('content z-10')}>{children}</div>
      </div>
      <Footer className={cx('relative z-10')} />
    </>
  );
}

export default DefaultLayout;
