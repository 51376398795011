import styles from './StepList.module.scss';
import classNames from 'classnames/bind';
import React from 'react';
import RoundedButton from '~/components/Layouts/components/RoundedButton';
import { Swiper, SwiperSlide } from 'swiper/react';

const cx = classNames.bind(styles);
function StepList({ className, steps, props }) {
  return (
    <Swiper
      className={cx(className, 'step-list', 'flex w-full max-w-screen-2xl px-10 m-auto')}
      slidesPerView={'auto'}
      spaceBetween={20}
      breakpoints={{
        640: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 4,
        },
      }}
      {...props}
    >
      {steps.map((step, i) => (
        <SwiperSlide key={i} className={cx('step', 'flex flex-col w-11/12 lg:w-full')}>
          <span className={cx('text-2xl')}>Step {i + 1}</span>
          <img className={cx('step__image')} src={step.image} alt={step.title} />
          <span className={cx('step__title', 'text-xl lg:text-2xl font-bold')}>{step.title}</span>
          <span className={cx('step__desc', 'text-sm mb-4')}>{step.description}</span>
          <RoundedButton onPress={() => {}} fontSize="14px">
            Contact us now!
          </RoundedButton>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
export default StepList;
