import styles from './PrimaryButton.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

function PrimaryButton({ className, children, isBlack, onClick }) {
  return (
    <div className={cx('button', { 'button--black': isBlack })}>
      <button className={cx(className, 'rounded-full w-fit py-4 px-6 text-white')} onClick={onClick}>
        {children}
      </button>
    </div>
  );
}
export default PrimaryButton;
