import styles from './Footer.module.scss';
import classNames from 'classnames/bind';
import { twitterFill, facebookFill, linkedInFill } from '~/assets/images';
import { Link } from 'react-router-dom';
import CircleButton from '../CircleButton/CircleButton';

const cx = classNames.bind(styles);
function Footer({ className }) {
  return (
    <div className={cx(className, 'footer')}>
      <div className={cx('max-w-full flex flex-col py-10 lg:py-24')}>
        <div
          className={cx(
            'footer',
            'flex flex-col lg:flex-row w-full max-w-screen-2xl px-5 lg:px-10 justify-between m-auto h-full',
          )}
        >
          <div className={cx('footer__info', 'flex flex-col w-full lg:w-6/12')}>
            <span className={cx('sec-desc')}>SOFIN Network</span>
            <ul className={cx('mt-9 text-base lg:text-2xl')}>
              <li>Address: 216-220 Tran Hung Dao, An Hai Tay Ward, Son Tra District, Danang City, Vietnam</li>
              <li>Contact: support@sofin.foundation</li>
              <li>Hotline: 09xxxxxxxx</li>
            </ul>
          </div>
          <div className={cx('footer__contact', 'w-full lg:w-5/12 mt-10 lg:mt-0')}>
            <span>We also make emails</span>
            <p className={cx('lg:mt-4 text-base lg:text-2xl')}>*All things ad creative directly to your inbox</p>
            <div className={cx('super-input', 'mt-10 lg:mt-24')}>
              <input type="text" placeholder="Enter your email here" />
              <CircleButton>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M12.3524 20.6169L21.5965 11.3727M21.5965 11.3727L12.5506 2.32677M21.5965 11.3727L1.65332 11.582"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinejoin="round"
                  />
                </svg>
              </CircleButton>
            </div>
          </div>
        </div>
        <div
          className={cx(
            'flex flex-col lg:flex-row lg:items-center mt-10 lg:mt-24 w-full max-w-screen-2xl m-auto px-5 lg:px-10',
          )}
        >
          <span className={cx('lg:mr-16 text-base lg:text-2xl')}>Follow us: </span>
          <ul className={cx('footer__socials', 'flex items-center')}>
            <li className={cx('footer__socials__item')}>
              <Link className={cx('text-base lg:text-2xl')}>Website SOFIN AI</Link>
            </li>
            <li className={cx('footer__socials__item')}>
              <Link>
                <img src={facebookFill} alt="SOFIN NETWORK" />
              </Link>
            </li>
            <li className={cx('footer__socials__item')}>
              <Link>
                <img src={twitterFill} alt="SOFIN NETWORK" />
              </Link>
            </li>
            <li className={cx('footer__socials__item')}>
              <Link>
                <img src={linkedInFill} alt="SOFIN NETWORK" />
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className={cx('footer__copyright', 'flex w-full max-w-screen-2xl px-5 lg:px-10 m-auto h-full')}>
        <span className={cx('text-base lg:text-2xl')}>Sofin - 2023 - All rights reserved</span>
      </div>
    </div>
  );
}

export default Footer;
