import styles from './PartnerSwiper.module.scss';
import classNames from 'classnames/bind';
import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
const cx = classNames.bind(styles);

function PartnerSwiper({ className, partners, slideClassName, props }) {
  return (
    <Swiper
      slidesPerView={3}
      spaceBetween={0}
      autoplay={{
        delay: 2000,
        disableOnInteraction: false,
      }}
      breakpoints={{
        640: {
          slidesPerView: 3,
        },
        768: {
          slidesPerView: 4,
        },
        1024: {
          slidesPerView: 7,
        },
      }}
      modules={[Autoplay]}
      className={cx('partner-swiper', className)}
      {...props}
    >
      {partners.map((partner, i) => (
        <SwiperSlide
          key={i}
          className={cx(slideClassName, 'partner-swiper__slide', 'flex items-center justify-center py-16 px-9')}
        >
          <img className={cx('partner-swiper__slide__icon', 'mr-4')} src={partner.icon} alt={partner.name} />
          <span className={cx('text-sm lg:text-3xl')}>{partner.name}</span>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
export default PartnerSwiper;
