import { LENGTH_OF_THOUSAND, LENGTH_OF_MILLION, LENGTH_OF_BILLION } from './constant';
export const shortenValue = (value) => {
  value = Math.round(value);
  if (value.toString().length < LENGTH_OF_THOUSAND) {
    return value;
  }

  if (value.toString().length < LENGTH_OF_MILLION) {
    return Number((value / 1e3).toFixed(1)).toString() + 'K';
  }

  if (value.toString().length < LENGTH_OF_BILLION) {
    return Number((value / 1e6).toFixed(1)).toString() + 'M';
  }

  if (value.toString().length >= LENGTH_OF_BILLION) {
    return Number((value / 1e9).toFixed(1)).toString() + 'B';
  }
  return value;
};
