import styles from './CircleButton.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

function CircleButton({ className, children, background, color, border, padding, onClick }) {
  const buttonStyle = {
    backgroundColor: background ?? '#000',
    color: color ?? '#fff',
    border: border ?? 'unset',
    padding: padding ?? '1rem 1.5rem',
  };
  return (
    <button className={cx('circle-button', className)} onClick={onClick} style={buttonStyle}>
      {children}
    </button>
  );
}
export default CircleButton;
