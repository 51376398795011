import styles from './RoundedButton.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

function roundedButton({ className, children, background, color, border, padding, fontSize, onClick, active }) {
  const buttonStyle = {
    backgroundColor: background ?? '#000',
    color: color ?? '#fff',
    border: border ?? 'unset',
    padding: padding ?? '1rem 1.5rem',
    fontSize: fontSize ?? '16px',
  };
  return (
    <button
      className={cx('rounded-button', className, { 'rounded-button--active': active })}
      onClick={onClick}
      style={buttonStyle}
    >
      {children}
    </button>
  );
}
export default roundedButton;
