//   logo: require('~/..').default,
export const logo = require('~/assets/images/site/sofin-network.png');
export const sofin1 = require('~/assets/images/site/sofin-1.png');
export const arrowBottom = require('~/assets/images/site/arrow-bottom.png');
export const banner1 = require('~/assets/images/banners/banner-1.png');
export const banner2 = require('~/assets/images/banners/banner-2.png');
export const banner3 = require('~/assets/images/banners/banner-3.png');
export const banner4 = require('~/assets/images/banners/banner-4.png');
export const sofin2 = require('~/assets/images/site/sofin-2.png');
export const like = require('~/assets/images/site/like.png');
export const star = require('~/assets/images/site/star.png');
export const facebook = require('~/assets/images/icons/facebook.png');
export const telegram = require('~/assets/images/icons/telegram.png');
export const tiktok = require('~/assets/images/icons/tiktok.png');
export const twitch = require('~/assets/images/icons/twitch.png');
export const twitter = require('~/assets/images/icons/twitter.png');
export const youtube = require('~/assets/images/icons/youtube.png');
export const icon1 = require('~/assets/images/icons/icon-1.png');
export const icon2 = require('~/assets/images/icons/icon-2.png');
export const icon3 = require('~/assets/images/icons/icon-3.png');
export const icon4 = require('~/assets/images/icons/icon-4.png');
export const java = require('~/assets/images/icons/java.png');
export const swift = require('~/assets/images/icons/swift.png');
export const react = require('~/assets/images/icons/react.png');
export const kotlin = require('~/assets/images/icons/kotlin.png');
export const animate = require('~/assets/images/site/animate-1.gif');
export const kol1 = require('~/assets/images/kols/kol-1.png');
export const kol2 = require('~/assets/images/kols/kol-2.png');
export const kol3 = require('~/assets/images/kols/kol-3.png');

export const facebookFill = require('~/assets/images/icons/facebook-fill.png');
export const linkedInFill = require('~/assets/images/icons/linkedin-fill.png');
export const twitterFill = require('~/assets/images/icons/twitter-fill.png');
export const bottomArrow = require('~/assets/images/icons/icon-bottom-arrow.png');
export const close = require('~/assets/images/icons/icon-close.png');
export const menu = require('~/assets/images/icons/icon-menu.png');
