import styles from './CreatorList.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

function CreatorList({ className, creators, props }) {
  return (
    <div className={cx(className, 'creator-list', 'w-full flex flex-col')} {...props}>
      {creators.map((creator, i) => (
        <div key={i} className={cx('creator-list__item', 'flex justify-between items-center')}>
          <div className={cx('w-full max-w-screen-2xl m-auto my-24 px-10 flex flex-col lg:flex-row')}>
            <div className={cx('creator-list__item__name', 'w-full lg:w-2/5 text-base lg:text-4xl mb-4 lg:mb-0')}>
              {creator.name}
            </div>
            <div className={cx('creator-list__item__desc', 'w-full lg:w-3/5 text-sm lg:text-2xl')}>{creator.desc}</div>
          </div>
        </div>
      ))}
    </div>
  );
}
export default CreatorList;
