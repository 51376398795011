import React, { useEffect, useState } from 'react';
import styles from './Section5.module.scss';
import classNames from 'classnames/bind';
import { banner4, icon1, icon2, icon3, icon4 } from '~/assets/images';
import StepList from '~/components/Layouts/components/StepList/StepList';
const cx = classNames.bind(styles);

const steps = [
  {
    image: icon1,
    title: 'Connect to join!',
    description:
      'Join our network within a few minutes. Tell us a bit about yourself and SOFIN will connect with you soon! ',
  },
  {
    image: icon2,
    title: 'Negotiate the benefits',
    description:
      'Upon connecting, SOFIN and the creator/leader will discuss operation goals, development strategy, and decentralization & mutual obligations. ',
  },
  {
    image: icon3,
    title: 'Make the content',
    description:
      "After completing the agreement, you can start creating content under SOFIN's support. If you are a beginner, join the SOFIN Academy Program to hone your knowledge and skills before starting work. ",
  },
  {
    image: icon4,
    title: 'Get paid!',
    description: 'Your income will be based on actual sales and pre-approved terms. ',
  },
];
function Section5() {
  return (
    <div className={cx('sec-5', 'w-full flex flex-col my-10 lg:my-24')}>
      <div className={cx('flex w-full max-w-screen-2xl px-10 m-auto relative')}>
        <img src={banner4} alt="SOFIN NETWORK" />
        <div className={cx('sec-5__heading')}>
          SOFIN<br></br>Network Process
          <p className={cx('text-base lg:text-2xl')}>Only 4 steps route to being a SOFIN creator!</p>
        </div>
      </div>
      <StepList steps={steps} />
    </div>
  );
}

export default Section5;
