import styles from './Header.module.scss';
import classNames from 'classnames/bind';
import { logo, close, menu } from '~/assets/images';
import RoundedButton from '../RoundedButton/RoundedButton';
import React, { useState, useEffect } from 'react';
import LanguageSwitch from '../LanguageSwitch/LanguageSwitch';
import CircleButton from '../CircleButton/CircleButton';
const cx = classNames.bind(styles);
function Header({ className }) {
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [isHide, setIsHide] = useState(false);
  const [isTop, setIsTop] = useState(true);
  const [isShowSidebar, setIsShowSidebar] = useState(false);

  const onToggleSidebar = (isShow) => {
    setIsShowSidebar(isShow);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setPrevScrollPos(currentScrollPos);
      setIsHide(prevScrollPos < currentScrollPos && !isShowSidebar);
      setIsTop(window.scrollY < 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);
  return (
    <div
      className={cx(className, 'header', 'max-w-full fixed top-0 left-0 right-0 h-24 py-2.5 z-50', {
        'header-hide': isHide,
        'header-top': isTop,
      })}
    >
      <div className={cx('flex max-w-screen-2xl justify-between m-auto h-full px-5 lg:px-10 items-center')}>
        <img className={cx('header__logo', 'h-full')} src={logo} alt="SOFIN Network" />
        <div className={cx('header__actions', 'flex items-center')}>
          <div className={cx('header__actions__sidebar', { 'header__actions__sidebar--active': isShowSidebar })}>
            <CircleButton className={cx('header__actions__close')} onClick={() => onToggleSidebar(false)}>
              <img src={close} alt="Close" />
            </CircleButton>
            <span className={cx('lg:mr-5')}>SOFIN Network Tutorial</span>
            <RoundedButton className={cx('header__actions__button')}>Get in touch</RoundedButton>
          </div>
          <LanguageSwitch />
          <CircleButton className={cx('header__actions__menu')} onClick={() => onToggleSidebar(true)}>
            <img src={menu} alt="Menu" />
          </CircleButton>
        </div>
      </div>
    </div>
  );
}

export default Header;
