import React, { useEffect, useState, useRef } from 'react';
import styles from './Section1.module.scss';
import classNames from 'classnames/bind';
import { sofin1, arrowBottom } from '~/assets/images';
import { intro, introHevc, introWebm } from '~/assets/videos';
import RoundedButton from '~/components/Layouts/components/RoundedButton';
const cx = classNames.bind(styles);
function Section1() {
  const [scale, setScale] = useState(40);
  const videoRef = useRef(null);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    const { clientX, clientY } = e;
    setCursorPosition({ x: clientX, y: clientY });
  };

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const screenHeight = window.innerHeight;
    const videoElement = document.getElementById('video-element');

    if (videoElement) {
      const videoTop = videoElement.offsetTop;
      const distance = scrollY - videoTop;

      const scaleValue = Math.floor(40 + Math.min((100 * distance) / screenHeight, 100));
      if (scaleValue > 90) {
        setScale(100);
      } else if (scaleValue <= 100 && scaleValue >= 40) {
        setScale(scaleValue);
      }
    }
  };

  useEffect(() => {
    console.log(scale);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleToggleFullscreen = () => {
    const videoElement = videoRef.current;

    if (videoElement) {
      if (videoElement.requestFullscreen) {
        // For most browsers (not iOS Safari)
        if (!document.fullscreenElement) {
          videoElement.requestFullscreen().catch((err) => console.error('Error entering fullscreen:', err));
        } else {
          document.exitFullscreen().catch((err) => console.error('Error exiting fullscreen:', err));
        }
      } else if (videoElement.webkitEnterFullscreen) {
        // For iOS Safari
        if (!document.webkitFullscreenElement) {
          videoElement.webkitEnterFullscreen().catch((err) => console.error('Error entering fullscreen:', err));
        } else {
          document.webkitExitFullscreen().catch((err) => console.error('Error exiting fullscreen:', err));
        }
      }
    }
  };

  return (
    <>
      <div
        className={cx('Section1', 'flex w-full lg:min-h-screen max-w-screen-2xl px-5 lg:px-10 m-auto relative')}
        onMouseMove={handleMouseMove}
      >
        <img
          src={sofin1}
          className={cx('absolute right-0 z-0')}
          alt="SOFIN NETWORK"
          style={{
            transform: `translate(${cursorPosition.x - 1000}px, ${cursorPosition.y - 500}px)`,
          }}
        />
        <div className={cx('w-full lg:w-7/12 flex justify-center flex-col relative z-1 pt-52 lg:pt-0 lg:pr-24')}>
          <img src={arrowBottom} className={cx('w-24 h-24')} alt="SOFIN NETWORK" />
          <span className={cx('text-4xl lg:text-7xl')} data-aos="fade-up">
            Say hi! — we’ are SOFIN Network.
          </span>
          <p className={cx('text-xl mb-10')} data-aos="fade-up">
            We reaches a new generation of creativity <br></br>| the collaboration | creators | the optimal level |
            revenue |
          </p>
          <RoundedButton onClick={() => {}}>Start with us!</RoundedButton>
        </div>
        <div
          className={cx('intro-video', 'lg:w-5/12 fixed top-1/4 lg:top-1/2 right-5 lg:right-20')}
          style={{
            width: `${scale}%`,
            right: scale > 90 ? 0 : '',
            borderRadius: scale > 90 ? 0 : 20,
          }}
        >
          <video autoPlay muted playsInline id="video-element" loop ref={videoRef}>
            <source src={intro} type="video/mp4" />
            <source src={introHevc} type="video/mp4" />
            <source src={introWebm} type="video/webm" />
            Your browser does not support the video tag.
          </video>
          <div className={cx('intro-video__overlay')}>
            <button onClick={handleToggleFullscreen}>Play reel</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Section1;
